import React from "react"
import Layout from "../layout/layout";
import Codepen from '../../content/assets/codepen.png'
import Github from '../../content/assets/github.png'

const AboutPage = () => {
    return (
        <Layout>
            <h1>About</h1>


            <p>Hello, <br/>
                I am a software developer living in Cologne, Germany</p>

            <h3>Stuff I do</h3>
            <ul>
                <li>CTF & coding challanges</li>
                <li>computer security</li>
                <li>creating web-apps, websites</li>
                <li>listening to electronic music</li>
                <li>photography</li>
            </ul>

             <a href="https://github.com/borian" target="_blank" rel="noopener noreferrer">
                 <img src={Github} alt="Github Profile Borian"/>
                 </a>
             <a className={'ml-5'} href="https://codepen.io/borian" target="_blank" rel="noopener noreferrer">
                 <img src={Codepen} alt="Codepen Profile Borian" />
             </a>
        </Layout>
    )
};


export default AboutPage


